import {
	PageLayout,
	Card,
	CardBody,
	Button,
	CardPlaceholder,
	Permissions,
	Icon,
	Icons,
	useAuthorisationContext,
	useLangContext,
	StringHelpers,
} from 'carrier-fe';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
	const { hasPolicyAccess, hasPermissionAccess } = useAuthorisationContext();

	const { getLangPage } = useLangContext();
	const [lang, setLang] = useState<any>(null);

	const navigate = useNavigate()

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	const load = async () => {
		getLangPage('registration/dashboard')
			.then((res: any) => {
				if (typeof res === 'undefined' || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = StringHelpers.title(
						res?.page.title ?? 'Dashboard'
					);
				}
			})
			.catch((err: any) => console.error(err));
	};

	const getTileById = (id: string) => {
		if (!lang || !lang.page || !lang.page.tiles) return null;

		for (let i = 0; i < lang.page.tiles.length; i++) {
			if (lang.page.tiles[i]['id'] === id) {
				return lang.page.tiles[i];
			}
		}

		return null;
	};

	const renderRegistrationTile = () => {
		if (
			!hasPolicyAccess({
				name: 'toshiba_registration',
				method: 'viewAny',
			}) &&
			!hasPolicyAccess({
				name: 'viessmann_registration',
				method: 'viewAny',
			})
		)
			return null;

		let content = getTileById('registration.installation.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.PROFILE}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Registrations'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	const renderWarrantiesTile = () => {
		if (
			!hasPolicyAccess({ name: 'toshiba_warranty', method: 'viewAny' }) &&
			!hasPolicyAccess({ name: 'viessmann_warranty', method: 'viewAny' })
		)
			return null;

		let content = getTileById('registration.warranty.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.APARTMENT}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Warranties'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	const renderClaimsTile = () => {
		if (!hasPolicyAccess({ name: 'toshiba_claim', method: 'viewAny' }))
			return null;

		let content = getTileById('registration.claim.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Claims'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	const renderCustomersTile = () => {
		if (!hasPermissionAccess(Permissions.CUSTOMER_INDEX)) return null;

		let content = getTileById('registration.customer.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Customers'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate('/customers')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title ?? 'Dashboard')}
		>
			<div className="dashboard--inner--wrapper d-grid gap-4">
				{lang === null ? (
					<>
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
					</>
				) : (
					<>
						{renderRegistrationTile()}
						{renderWarrantiesTile()}
						{renderClaimsTile()}
						{renderCustomersTile()}
					</>
				)}
			</div>
		</PageLayout>
	);
}

export default Dashboard;
