import {
    API_ENDPOINTS,
    DataTable,
    formatDate,
    FormInput,
    Icon,
    Icons,
    ImageType,
    PageLayout,
    Permissions,
    replaceKeyWithValue,
    route,
    StringHelpers,
    useAuthorisationContext,
    useLangContext,
} from 'carrier-fe';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerCompanyItem } from '../../types/customer';

function CustomerPage() {
    const [refreshOnChange] = useState(new Date().toISOString());
    const [lang, setLang] = useState<any>(null);
    const { crud, models, fields } = useLangContext();

    const navigate = useNavigate();
    const { hasPermissionAccess } = useAuthorisationContext();

    const handleOptionSelect = (
        action: string | ImageType | null,
        data: CustomerCompanyItem
    ) => {
        const { id } = data;

        const actions: { [key: string]: () => void } = {
            view: () => navigate(`/customers/${id}`),
        };

        if (typeof action === 'string' && actions[action]) {
            actions[action]();
        }
    };

    const colMapping = (data: CustomerCompanyItem) => {
        return [
            {
                id: 'name',
                label: StringHelpers.title(fields?.name || 'Name'),
                value: data.name || `${data.first_name} ${data.last_name}` || '-',
                sortable: true,
            },
            {
                id: 'contact',
                label: StringHelpers.title(models?.contact || 'Contact'),
                value: data.email || '-',
                sortable: true,
            },
            {
                id: 'national_account',
                label: StringHelpers.title(
                    crud?.models?.national_account || 'National Account'
                ),
                value: data.type === 'national_account' ? 'Y' : 'N',
                sortable: true,
            },
            {
                id: 'created_at',
                label: StringHelpers.title(fields?.created_at || 'Created'),
                value: formatDate(data.created_at) || '-',
                sortable: true,
            },
            {
                id: 'deleted_at',
                label: StringHelpers.title(fields?.archived_at || 'Archived'),
                value: formatDate(data.deleted_at) || '-',
                sortable: true,
            },
            {
                id: 'actions',
                label: StringHelpers.title(crud?.page?.actions || 'Actions'),
                value: data.permissions.view && (
                    <FormInput
                        type="select"
                        label={StringHelpers.title(crud?.page?.actions || 'Actions')}
                        name="actions"
                        value=""
                        onChange={(value: string | ImageType | null | boolean | string[]) => {
                            if (typeof value === 'string') {
                                handleOptionSelect(value, data);
                            }
                        }}
                        options={[{
                                label: StringHelpers.title(
                                    crud?.select_labels?.view || 'View'
                                ),
                                value: 'view',
                            },
                        ]}
                        noMargin
                    />
                ),
            },
        ];
    };

    const actionItems = () => {
        let items = [];
        if (hasPermissionAccess(Permissions.CUSTOMER_INDEX)) {
            items.push({
                label: crud?.buttons?.create?.default || 'Create',
                disabled: false,
                href: '/customers/create',
                icon: <Icon icon={Icons.ADD} />,
            });
        }
        return items;
    };

    const title = useMemo(
        () =>
            StringHelpers.title(
                replaceKeyWithValue(
                    crud?.pages?.index.title || ':model List ',
                    'model',
                    models?.customer
                )
            ),
        [crud]
    );

    return (
        <PageLayout title={title ?? 'Customer(s) List'}>
            <DataTable<CustomerCompanyItem>
                routeEndpoint={route(API_ENDPOINTS.REGISTRATION.CUSTOMER.INDEX)}
                colMapping={colMapping}
                items={actionItems()}
                incrementPaginationButtons
                refreshOnChange={refreshOnChange}
                canViewArchived
            />
        </PageLayout>
    );
}

export default CustomerPage;
