import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Root from "../pages/root";
import Dashboard from "../pages/dashboard";
import { AuthenticatedLayout, Platforms } from "carrier-fe";
import CustomerPage from '../pages/customer';
import CustomerCompanyForm from '../pages/customer/form';
import CustomerCompanyView from '../pages/customer/view';

function Router() {
  return (
    <BrowserRouter>
      <Routes>

        <Route index path="/" element={<Root />} />

        {/*
         * TODO - FOLLOWING ROUTES NEED TO BE AUTH PROTECTED
         *      - IS A TOKEN SET IN THE COOKIE?
         *      - IS THAT TOKEN VALID (CHECK VIA THE SERVER?
         *      - IF NOT -> LOGOUT AND REDIRECT TO LOGIN
         */}
        <Route
          path="/"
          element={<AuthenticatedLayout platform={Platforms.REGISTRATION} />}
        >
          <Route index path="/dashboard" element={<Dashboard />} />

					<Route path="/customers">
						<Route
							index
							element={<CustomerPage/>}
						/>
						<Route
							path=":customerId"
							element={<CustomerCompanyView/>}
						/>
						<Route
							path="create"
							element={<CustomerCompanyForm/>}
						/>
						<Route
							path=":customerId/edit"
							element={<CustomerCompanyForm/>}
						/>
					</Route>

				</Route>

        {/* TODO - MAKE A PRETTY PAGE IN THE COMPONENT LIBRARY */}
        <Route path="*" element={<p>404</p>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
