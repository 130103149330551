import {
  API_ENDPOINTS,
  Button,
  ButtonTypes,
  Card,
  CardBody,
  customToast,
  DataTable,
  Dropdown,
  formatDate,
  Icons,
  PageLayout,
  replaceKeyWithValue,
  route,
  StringHelpers,
  SubTitle,
  useDisabledContext,
  useLangContext,
  useQuery,
  Variants,
  WidthConstrainedContainer,
  Modal,
  Spinner,
} from 'carrier-fe';
import axios from 'axios';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerCompanySite } from '../../../types/CustomerCompanySite';
import { CustomerCompanyDetails } from '../../../types/CustomerCompanyDetails';

// TODO - move to the FE library maybe
const InfoRow = ({
  title,
  value,
}: {
  title: string;
  value: string | undefined | null;
}) => (
  <div className="d-flex flex-column mb-3">
    <strong>{title}</strong>
    <div>{value || '-'}</div>
  </div>
);

const InfoGroup = ({ children }: { children: React.ReactNode }) => {
  return <div className="col-12 col-lg-6 col-xxl-4">{children}</div>;
};

/*
 *  MAIN COMPONENT
 */
function CustomerCompanyView() {
  const { customerId } = useParams();

  const { crud, fields } = useLangContext();
  const { disabled, setDisabled } = useDisabledContext();
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [archiving, setArchiving] = useState<boolean>(false);

  const { data, error, isLoading, refetch } = useQuery<{
    data: CustomerCompanyDetails;
  }>(
    route(API_ENDPOINTS.REGISTRATION.CUSTOMER.VIEW, {
      customerId: String(customerId),
    }),
    {
      method: 'GET',
    }
  );

  useEffect(() => {
    if (error) {
      customToast({
        title:
          (error?.response?.data as any)?.message ||
          'An error occurred. Please refresh the page to try again.',
        variant: Variants.Danger,
      });
    }
  }, [error]);

  const handleArchive = async () => {
    setArchiving(true);
    setDisabled(true);

    try {
      const response = await axios.delete(
        route(API_ENDPOINTS.REGISTRATION.CUSTOMER.DELETE, {
          customerId: String(customerId),
        })
      );

      customToast({
        title: response?.data?.message || 'Success.',
        variant: Variants.Success,
      });

      refetch();
    } catch (error) {
      customToast({
        // @ts-ignore
        title: error?.response?.data?.message || 'An internal error occurred.',
        variant: Variants.Danger,
      });
    } finally {
      setDisabled(false);
      setArchiving(false);
      setArchiveModalOpen(false);
    }
  };

  const getActions = useCallback((): DropdownItemProps[] => {
    if (!rootData?.permissions) return [];
    const { permissions } = rootData;

    const actions: DropdownItemProps[] = [];

    if (permissions.update) {
      actions.push({
        label: crud?.buttons?.edit?.default || 'Edit',
        onClick: () => navigate(`/customers/${customerId}/edit`),
        variant: Variants.Dark,
        icon: Icons.EDIT,
        disabled,
      });
    }

    if (permissions.delete) {
      actions.push({
        label: crud?.buttons?.archive?.default || 'Archive',
        onClick: () => setArchiveModalOpen(true),
        variant: Variants.Danger,
        icon: Icons.DELETE,
        disabled,
      });
    }

    return actions;
  }, [data?.data, crud, customerId, disabled]);

  const rootData = data?.data;
  const actions = getActions();
  const primarySite = rootData?.primary_site;
  const individual = rootData?.primary_individual;
  const isNationalAccount = rootData?.type === 'national_account';
  const nationalAccountSubTitle = StringHelpers.title(
    `${crud?.sub_titles?.primary || 'Primary'} ${
      crud?.models?.contact || 'Contact'
    } ${crud?.sub_titles?.details || 'Details'}`
  );
  const pageTitle = StringHelpers.title(
    replaceKeyWithValue(
      crud?.pages?.view.title || 'View :model',
      'model',
      crud?.models?.customer || 'Customer'
    )
  );

  const colMapping = (data: CustomerCompanySite) => {
    return [
      {
        id: 'name',
        label: StringHelpers.title(fields?.name || 'Name'),
        value: StringHelpers.title(data.name || '-'),
        sortable: true,
      },
      {
        id: 'address_line_1',
        label: StringHelpers.title(fields?.address_line_1 || 'Address Line 1'),
        value: data?.address_line_1 || '-',
        sortable: true,
      },
      {
        id: 'town_city',
        label: StringHelpers.title(fields?.town_city || 'Town / City'),
        value: data.town_city || '-',
        sortable: true,
      },
      {
        id: 'postcode_zipcode',
        label: StringHelpers.title(
          fields?.postcode_zipcode || 'Postcode / Zipcode'
        ),
        value: data?.postcode_zipcode || '-',
        sortable: true,
      },
      {
        id: 'country_code_iso_3',
        label: StringHelpers.title(fields?.country_code_iso_3 || 'Country'),
        value: data.country_code_iso_3 || '-',
        sortable: true,
      },
      {
        id: 'created_at',
        label: StringHelpers.title(fields?.created_at || 'Created'),
        value: formatDate(data.created_at) || '-',
        sortable: true,
      },
    ];
  };

  return (
    <PageLayout title={pageTitle}>
      <WidthConstrainedContainer>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="mb-4 d-flex justify-content-between align-items-center">
              <Button
                label={StringHelpers.title(crud.buttons.back.default || 'Back')}
                onClick={() => navigate('/customers')}
                variant={Variants.Dark}
                type={ButtonTypes.Outline}
                disabled={disabled}
              />
              {!isNationalAccount && actions?.length > 0 && (
                <Dropdown
                  label={StringHelpers.title(
                    crud?.buttons.actions.default || 'Actions'
                  )}
                  items={actions}
                  type={ButtonTypes.Solid}
                  variant={Variants.Dark}
                  disabled={disabled}
                />
              )}
            </div>
            <Card>
              <CardBody>
                <SubTitle
                  title={
                    isNationalAccount
                      ? nationalAccountSubTitle
                      : StringHelpers.title(
                          crud?.sub_titles?.details ?? 'Details'
                        )
                  }
                  className="mb-3 w-100"
                  style={{ color: '#464C5E' }}
                />
                <div className="row mb-4">
                  <InfoGroup>
                    <InfoRow
                      title={StringHelpers.title(
                        `${crud?.models?.contact || 'Contact'}`
                      )}
                      value={individual?.name}
                    />
                    <InfoRow
                      title={StringHelpers.title(
                        `${crud?.fields?.email || 'Email'}`
                      )}
                      value={individual?.email}
                    />
                  </InfoGroup>

                  <InfoGroup>
                    <InfoRow
                      title={StringHelpers.title(
                        `${fields?.mobile_phone || 'Mobile'}`
                      )}
                      value={individual?.mobile_phone_number}
                    />
                    <InfoRow
                      title={StringHelpers.title(
                        `${fields?.landline_phone || 'Landline'}`
                      )}
                      value={individual?.landline_phone_number}
                    />
                  </InfoGroup>

                  <InfoGroup>
                    <InfoRow
                      title={StringHelpers.title(
                        `${fields?.created_at || 'Created'}`
                      )}
                      value={formatDate(individual?.created_at ?? '-')}
                    />
                    <InfoRow
                      title={StringHelpers.title(
                        `${crud?.sub_titles?.last || 'Last'} ${
                          crud?.fields?.updated || 'Updated'
                        }`
                      )}
                      value={formatDate(individual?.updated_at || '-')}
                    />
                    {individual?.deleted_at && (
                      <InfoRow
                        title={StringHelpers.title(
                          `${fields?.archived_at || 'Archived'}`
                        )}
                        value={formatDate(individual?.deleted_at || '-')}
                      />
                    )}
                  </InfoGroup>
                </div>
                {/* Company details */}
                <SubTitle
                  title={StringHelpers.title(
                    `${crud?.models?.company} ${crud?.sub_titles?.details}`
                  )}
                  className="mb-3 w-100"
                  style={{ color: '#464C5E' }}
                />
                <div className="row">
                  <InfoGroup>
                    <InfoRow
                      title={StringHelpers.title(`${fields?.name || 'Name'}`)}
                      value={rootData?.name}
                    />
                    {!isNationalAccount && (
                      <>
                        <InfoRow
                          title={StringHelpers.title(
                            `${fields?.address_line_1 || 'Address Line 1'}`
                          )}
                          value={primarySite?.address?.address_line_1}
                        />
                        <InfoRow
                          title={StringHelpers.title(
                            `${fields?.address_line_2 || 'Address Line 2'}`
                          )}
                          value={primarySite?.address?.address_line_2}
                        />
                        <InfoRow
                          title={StringHelpers.title(
                            `${fields?.address_line_3 || 'Address Line 3'}`
                          )}
                          value={primarySite?.address?.address_line_3}
                        />
                      </>
                    )}
                  </InfoGroup>

                  {!isNationalAccount && (
                    <InfoGroup>
                      <InfoRow
                        title={StringHelpers.title(
                          `${fields?.town_city || 'Town / City'}`
                        )}
                        value={primarySite?.address?.town_city}
                      />
                      <InfoRow
                        title={StringHelpers.title(
                          `${fields?.state_county || 'State / County'}`
                        )}
                        value={primarySite?.address?.state_county}
                      />
                      <InfoRow
                        title={StringHelpers.title(
                          `${fields?.postcode_zipcode || 'Postcode / Zipcode'}`
                        )}
                        value={primarySite?.address?.postcode_zipcode}
                      />
                      <InfoRow
                        title={StringHelpers.title(
                          `${fields?.country_code_iso_3 || 'Country'}`
                        )}
                        value={primarySite?.address?.country_code_iso_3}
                      />
                    </InfoGroup>
                  )}

                  <InfoGroup>
                    <InfoRow
                      title={StringHelpers.title(
                        `${fields?.created_at || 'Created'}`
                      )}
                      value={formatDate(primarySite?.created_at || '-')}
                    />
                    <InfoRow
                      title={StringHelpers.title(
                        `${crud?.sub_titles?.last || 'Last'} ${
                          crud?.fields?.updated || 'Updated'
                        }`
                      )}
                      value={formatDate(primarySite?.updated_at || '-')}
                    />
                    {rootData?.deleted_at && (
                      <InfoRow
                        title={StringHelpers.title(
                          `${fields?.archived_at || 'Archived'}`
                        )}
                        value={formatDate(rootData?.deleted_at || '-')}
                      />
                    )}
                  </InfoGroup>
                </div>
              </CardBody>
            </Card>

            {isNationalAccount && (
              <DataTable
                routeEndpoint={route(API_ENDPOINTS.REGISTRATION.CUSTOMER.SITE, {
                  customerId: String(customerId),
                })}
                colMapping={colMapping}
                incrementPaginationButtons
              />
            )}
          </>
        )}
      </WidthConstrainedContainer>

      <Modal
        open={archiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
        title={StringHelpers.title(
          replaceKeyWithValue(
            crud?.modals?.archive.title || 'Archive :name?',
            'name',
            rootData?.name || 'Company'
          )
        )}
        confirmText={
          archiving
            ? crud.buttons.archive.submitting || 'Archiving...'
            : crud.buttons.archive.default || 'Archive'
        }
        closeText={crud.buttons.cancel.default || 'Cancel'}
        onConfirm={handleArchive}
        disabled={disabled || archiving}
        variant={Variants.Danger}
      >
        {crud?.modals?.archive.description.map((item: string, key: number) => {
          return (
            <p key={key}>
              {replaceKeyWithValue(item, 'name', rootData?.name ?? '')}
            </p>
          );
        })}
      </Modal>
    </PageLayout>
  );
}

export default CustomerCompanyView;
